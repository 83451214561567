#landing-container {
  /* min-height: calc(100vh - 77.375px); */
  min-height: 110vh;
  overflow: hidden;
  background-color: transparent;
}

#landing-bg {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
  /* background: url(../../css-assets/cover.jpg); */
  background-image: url(../../css-assets/streetlight-bg.jpg);
  /* background-position: fixed; */

  opacity: 0.5;
  background-size: cover;
}

#mountain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: auto;
  bottom: 0;
  z-index: -2;
  background-image: url("../../css-assets/mountain.svg");
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-position: 50% 400%;
  background-size: 100% 75%;
  animation: come-up 1s ease-in-out 2.7s forwards;
}

@keyframes come-up {
  from {
    background-position: 50% 400%;
  }
  to {
    background-position: 50% 180%;
  }
}
