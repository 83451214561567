#roadmap-container {
  /* background: linear-gradient(
    0deg,
    rgba(148, 90, 237, 0) 0%,
    theme("colors.blue.800") 100%
  ); */
  background: transparent;
  padding-bottom: 4rem;
  background-color: transparent;
  /* background by SVGBackgrounds.com */
}

div[class*="TimelineVerticalWrapper"],
timeline-main-wrapper {
  overflow-y: visible !important;
}

.vertical_alternating {
  margin-top: 68px;
  overflow: visible !important;
}

.vertical_alternating ::after {
  height: 200% !important;
  top: -87px;
}

.timeline-card-content {
  height: 100% !important;
}

.card-title {
  font-family: Sora;
  color: #eee !important;
}

.card-description {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 1px !important;
}

.vertical-item-row > div:nth-child(3) {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.timeline-card-content {
  min-height: auto !important;
}

@media only screen and (max-width: 640px) {
  .vertical_alternating {
    margin-top: 0px !important;
  }

  .vertical_alternating ::after {
    height: 100% !important;
    top: 0px !important;
  }

  .timeline-card-content {
    height: auto !important;
  }
}
