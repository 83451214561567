#benefits-prizes {
  background: linear-gradient(
    180deg,
    rgb(0, 26, 92) 0%,
    rgba(148, 90, 237, 0) 114%
  );
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box;
  height: 6px;
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: theme("colors.primary");
  border-radius: 8px;
  height: 6px;
  width: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: theme("colors.primary");
  cursor: pointer;
}
