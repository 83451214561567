@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  scroll-behavior: smooth; */
/* } */

body {
  margin: 0;
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070b28;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Uomo Expanded";
  src: url("./css-assets/Uomo Expanded.otf") format("opentype");
}

@font-face {
  font-family: "Uomo Expanded Bold";
  src: url("./css-assets/Uomo Expanded Bold.otf") format("opentype");
}

.glow {
  text-shadow: 0px 0px 5.53871px rgba(49, 181, 255, 0.26),
    0px 0px 21.4421px rgba(49, 156, 255, 0.5);
}

body [data-aos-delay="4900"][data-aos].aos-animate,
[data-aos][data-aos][data-aos-delay="4900"].aos-animate {
  transition-delay: 0.5s;
}

body [data-aos-delay="5000"][data-aos].aos-animate,
[data-aos][data-aos][data-aos-delay="5000"].aos-animate {
  transition-delay: 0.8s;
}

body [data-aos-delay="5300"][data-aos].aos-animate,
[data-aos][data-aos][data-aos-delay="5300"].aos-animate {
  transition-delay: 0.9s;
}

body [data-aos-delay="5700"][data-aos].aos-animate,
[data-aos][data-aos][data-aos-delay="5700"].aos-animate {
  transition-delay: 1.2s;
}
